import { SearchFilter } from "src/components/Filter/SearchFilter"
import { ParadiseAppliedFilters } from "src/components/Paradise/ParadiseAppliedFilters"
import {
  TParadiseHomesFilters,
  useParadiseHomesFilter,
} from "src/components/Paradise/ParadiseHomes/useParadiseHomesFilter"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  Ellipsis,
  IdWrapper,
  ParadiseLayout,
  ParadiseTitle,
  PillsWrapper,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { parseParadiseHomeSortField } from "src/data/paradise/paradiseHomes/logic/paradiseHome"
import { useFetchParadiseHomes } from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueries"
import { IParadiseHome } from "src/data/paradise/paradiseHomes/types/paradiseHomeQuerytypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { colorsLegacy } from "src/ui/colors"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { ColumnPopoverWrapper } from "src/ui/GridTable/useTableColumns/tableColumnStyles"
import { useSortParam } from "src/ui/GridTable/useTableColumns/useSortParam"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import FilterIcon from "src/ui/icons/calibrating.svg"
import InfoIcon from "src/ui/icons/info.svg"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"
import { formatUtcDate } from "src/utils/l10n"

const homesFilterLabel: Record<keyof TParadiseHomesFilters, string> = {
  home_id: "Home ID",
  home_name: "Home name",
  home_tag: "Home tag",
  home_offset: "Offset",
} as const

const LIMIT = 50

export function ParadiseHomes() {
  const { navigate } = useRouter()

  const { filter, sortedFilter, setFilter, setOffset, offset } =
    useParadiseHomesFilter()

  const { sortValue, setSortValue } = useSortParam()

  // If the user has filtered we want to show deleted homes aswell, otherwise just active homes is fetched
  const hasFiltered = Object.values(filter).some((f) => !!f)

  const tableColumns: TableColumn<IParadiseHome>[] = [
    {
      value: "home_id",
      label: "Home id",
      disabled: true,
      columnWidth: "min-content",
      disableClickPropagation: true,
      renderCell: (home) => (
        <div>
          <IdWrapper>
            <InternalLink to={Routes.ParadiseHome.location(home.id)}>
              {home.id}
            </InternalLink>
            {home.deleted && (
              <MTooltip title="Deleted">
                <InfoIcon
                  width={12}
                  fill={colorsLegacy.systemEmergencyForeground}
                />
              </MTooltip>
            )}
          </IdWrapper>
        </div>
      ),
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.home_id ?? undefined}
            placeholder="Search by id"
            onChange={(v) => setFilter("home_id", v)}
          />
          <MText variant="bodyS">Make sure to type in the exact ID</MText>
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      value: "name",
      label: "Name",
      disabled: true,
      columnWidth: 300,
      enableSort: true,
      renderCell: (home) => <Ellipsis>{home.name}</Ellipsis>,
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.home_name ?? undefined}
            placeholder="Search by name"
            onChange={(v) => setFilter("home_name", v)}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      value: "created_at",
      label: "Created at",
      columnWidth: "min-content",
      enableSort: true,
      renderCell: (home) => (
        <div>{formatUtcDate({ date: home.created_at, excludeTime: true })}</div>
      ),
    },
    {
      value: "created_by",
      label: "Created by",
      columnWidth: "min-content",
      disableClickPropagation: true,
      renderCell: (home) => (
        <div>
          <InternalLink
            to={Routes.ParadiseUser.location(home.created_by).pathname}
          >
            {home.created_by}
          </InternalLink>
        </div>
      ),
    },
    {
      value: "deleted_at",
      label: "Deleted at",
      columnWidth: "min-content",
      enableSort: true,
      renderCell: (home) => (
        <div>
          {home.deleted_at ? formatUtcDate({ date: home.deleted_at }) : "-"}
        </div>
      ),
    },
    {
      value: "owner_id",
      label: "Owner id",
      columnWidth: "min-content",
      disableClickPropagation: true,
      renderCell: (home) => (
        <div>
          <InternalLink
            to={Routes.ParadiseUser.location(home.owner.id).pathname}
          >
            {home.owner.id}
          </InternalLink>
        </div>
      ),
    },
    {
      value: "tags",
      label: "Tags",
      columnWidth: "min-content",
      renderCell: (home) => (
        <PillsWrapper $noWrap>
          {home.tags.map((tag, i) => (
            <MBadge key={i} size="x-small">
              {tag}
            </MBadge>
          ))}
        </PillsWrapper>
      ),
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.home_tag ?? undefined}
            placeholder="Search by tag"
            onChange={(v) => setFilter("home_tag", v)}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      value: "timezone",
      label: "Timezone",
      columnWidth: "auto",
      renderCell: (home) => <div>{home.timezone}</div>,
    },
  ]

  const fetchHomes = useFetchParadiseHomes({
    filter: {
      limit: LIMIT,
      offset: offset,
      home_id: filter.home_id || undefined,
      name: filter.home_name || undefined,
      tag: filter.home_tag || undefined,
      sort: parseParadiseHomeSortField(sortValue?.id)
        ? sortValue?.id
        : undefined,
      sort_by: sortValue?.order,
      deleted: hasFiltered ? undefined : false,
    },
    options: {
      keepPreviousData: true,
    },
  })
  const homes = fetchHomes.data?.homes

  const {
    headerElements,
    interactiveColumns,
    interactiveVisibleColumns,
    rows,
    updateColumnVisibility,
    templateColumns,
  } = useTableColumns<IParadiseHome>({
    columns: tableColumns,
    data: homes,
    options: { localStorageKey: "minut.paradise.homes.table" },
    sort: sortValue,
    onSortChange: setSortValue,
  })

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Homes
            {fetchHomes.isSuccess && (
              <MBadge color="info">
                Total: {fetchHomes.data?.total_count}
              </MBadge>
            )}
          </ParadiseTitle>
        }
      />
      <TopWrapper>
        <div>
          <ParadiseAppliedFilters
            filters={sortedFilter.map((f) => ({
              label: homesFilterLabel[f.key],
              value: String(f.value),
              onRemove: () => setFilter(f.key, null),
            }))}
          />
        </div>
        <DropdownMultiSelect
          label="Columns"
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          options={interactiveColumns}
          onChange={({ checked, option }) => {
            updateColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>

      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          if (homes) {
            // @ts-expect-error: noUncheckedIndexedAccess
            navigate(Routes.ParadiseHome.location(homes[index].id))
          }
        }}
        error={{
          hasError: fetchHomes.isError,
          title: fetchHomes.error?.message,
        }}
      />

      {fetchHomes.data && (
        <ParadisePager
          limit={LIMIT}
          offset={offset}
          setOffset={setOffset}
          totalCount={fetchHomes.data.total_count}
        />
      )}
    </ParadiseLayout>
  )
}
