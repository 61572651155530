import {
  TSetSearchParamsProp,
  TSortedSearchParams,
  useSearchParams,
} from "src/router/useSearchParams"
import { Nullable } from "src/utils/tsUtil"

type TParadiseHomesFiltersConfig = {
  home_id: string
  home_name: string
  home_tag: string
  home_offset: number
}

export type TParadiseHomesFilters = Nullable<TParadiseHomesFiltersConfig>
export type TSortedParadiseHomesFilters =
  TSortedSearchParams<TParadiseHomesFiltersConfig>

export function useParadiseHomesFilter(): {
  filter: TParadiseHomesFilters
  sortedFilter: TSortedParadiseHomesFilters
  setFilter: TSetSearchParamsProp<TParadiseHomesFilters>
  setOffset: (offset: number) => void
  offset: number
} {
  const {
    searchParams: filter,
    setSearchParams: setFilter,
    setSearchParamsAll,
    sortedSearchParams: sortedFilter,
  } = useSearchParams({
    keys: [
      {
        key: "home_offset",
        type: "number",
      },
      {
        key: "home_id",
        type: "string",
      },
      {
        key: "home_name",
        type: "string",
      },
      {
        key: "home_tag",
        type: "string",
        acceptFalsy: true,
      },
    ],
  })

  return {
    filter,
    sortedFilter: sortedFilter.filter((f) => f.key !== "home_offset"),
    setFilter: (key, value) => {
      if (key === "home_offset") {
        return
      }

      // Make sure we reset paging on filter
      setSearchParamsAll({
        [key]: value,
        home_offset: 0,
      })
    },
    setOffset: (offset: number) => {
      setFilter("home_offset", offset)
    },
    offset: filter.home_offset || 0,
  }
}
